import React, { useContext } from 'react';
import type { ReactElement } from 'react';
import AbTestValentinePage from './AbTest/AbTestValentinePage';
import type { Categories as CategoriesType } from '../ComponentTypes';
import ImageSlider from './ImageSlider';
import ButtonSlider from './ButtonSlider';
import Section from '../../Section/Section';
import { plpValentinePage } from '../../../../js/optimizely/fx/flags';
import { WebshopContext } from '../../WebshopContext/WebshopContextProvider';

interface CategoriesProps {
    categories: CategoriesType['links'];
    draggableWrapper?: ReactElement;
    isDraggable?: boolean;
    itemWrapper?: ReactElement;
    withImages: boolean;
}

const Categories = React.forwardRef<HTMLDivElement, CategoriesProps>(({ categories, draggableWrapper, isDraggable, itemWrapper, withImages }, ref) => {
    const shownCategories = categories.filter((c) => itemWrapper || c.show);
    const context = useContext(WebshopContext);
    const isVariationB = context.experimentVariation === plpValentinePage.variationB;
    const isVariationC = context.experimentVariation === plpValentinePage.variationC;

    if (!shownCategories.length) {
        return null;
    }

    if (isVariationB || isVariationC) {
        return <AbTestValentinePage mainCategories={categories}/>;
    }

    return (
        <Section noPadding hasBorderBottom ref={ref}>
            {withImages
                ? <ImageSlider categories={shownCategories} draggableWrapper={draggableWrapper} isDraggable={isDraggable} itemWrapper={itemWrapper} />
                : <ButtonSlider categories={shownCategories} draggableWrapper={draggableWrapper} isDraggable={isDraggable} itemWrapper={itemWrapper} />}
        </Section>
    );
});

export default Categories;
