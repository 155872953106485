import type React from 'react';
import Boxes from '../../Box/Boxes';
import UseTranslate from '../../../Translation/hooks/UseTranslate';
import styles from './AbTestSubCategories.module.scss';
import type { Box as BoxComponentType } from '../../ComponentTypes';

type TranslateMapping = {
    pageId: number;
    translateImageUriId: string;
    translateTitleId: string;
};

const staticSubCategories : TranslateMapping[] = [
    {
        pageId: 643,
        translateImageUriId: 'sub_category_choco_image',
        translateTitleId: 'sub_category_choco_title',
    },
    {
        pageId: 309,
        translateImageUriId: 'sub_category_cuddly_image',
        translateTitleId: 'sub_category_cuddly_title',
    },
    {
        pageId: 358,
        translateImageUriId: 'sub_category_underwear_image',
        translateTitleId: 'sub_category_underwear_title',
    },
    {
        pageId: 340,
        translateImageUriId: 'sub_category_glasses_image',
        translateTitleId: 'sub_category_glasses_title',
    },
    {
        pageId: 18,
        translateImageUriId: 'sub_category_jewellery_image',
        translateTitleId: 'sub_category_jewellery_title',
    },
    {
        pageId: 176,
        translateImageUriId: 'sub_category_books_image',
        translateTitleId: 'sub_category_books_title',
    },
    {
        pageId: 61,
        translateImageUriId: 'sub_category_wall_image',
        translateTitleId: 'sub_category_wall_title',
    },
];

const AbTestSubCategories: React.FC = () => {
    const translate = UseTranslate();
    const categoriesMap: BoxComponentType[] = staticSubCategories.map(({ pageId, translateImageUriId, translateTitleId }) => ({
        id: String(pageId),
        imageUri: translate('valentine_page', translateImageUriId, { strict: false }),
        pageId,
        title: translate('valentine_page', translateTitleId, { strict: false }),
        url: translate('valentine_page_links', pageId.toString(10), { strict: false }),
    }));

    return <div className={styles.AbTestSubCategories}>
        <Boxes
            title=""
            asSlider={false}
            editable={false}
            isLocked={false}
            boxType="small"
            items={categoriesMap}
        />
    </div>;
};

export default AbTestSubCategories;
