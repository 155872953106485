import type React from 'react';
import ClassNames from 'classnames';
import Section from '../../../Section/Section';
import styles from './AbTestValentinePage.module.scss';
import Promotion from '../../Promotion/Promotion';
import type { Categories as CategoriesType, Promotion as PromotionComponentData } from '../../ComponentTypes';
import AbTestSubCategories from './AbTestSubCategories';
import Heading from '../../../../../stories/Atoms/Heading/Heading';
import { useIsMobile } from '../../../hooks/useSize';
import { useIsInitialRequestMobile } from '../../../WebshopContext/WebshopContext';
import UseTranslate from '../../../Translation/hooks/UseTranslate';
import Translation from '../../../Translation/Translation';

type Props = {
    mainCategories: CategoriesType['links'];
};

type PromotionStatic = Pick<PromotionComponentData, 'alignment' | 'alignmentBannerMobile' | 'alignmentBlocksMobile' |
'bannerTextPosition' | 'lazyLoad' | 'promotionItems' | 'sectionType' | 'type'>;

const promotionStatic: PromotionStatic = {
    alignment: 'left',
    alignmentBannerMobile: 'top',
    alignmentBlocksMobile: 'row',
    bannerTextPosition: 'bottom',
    lazyLoad: false,
    promotionItems: [],
    sectionType: 'sm',
    type: 'promotion',
};

const AbTestValentinePage: React.FC<Props> = ({ mainCategories }) => {
    const categoryHim = mainCategories.find((cat) => cat.pageId === 199);
    const categoryHer = mainCategories.find((cat) => cat.pageId === 200);
    const isMobile = useIsMobile(useIsInitialRequestMobile());
    const translate = UseTranslate();

    return <>
        <Section isSm>
            <div className={styles.AbTestValentinePage__main_categories}>
                <div className={styles.AbTestValentinePage__two_column}>
                    {categoryHim
                        && <Promotion
                            editable={false}
                            component={{
                                ...promotionStatic,
                                ...{
                                    bannerAlt: categoryHim.name,
                                    bannerButtonText: translate('valentine_page', 'him_button_text'),
                                    bannerImageUri: translate('valentine_page', 'him_image_uri'),
                                    bannerPageId: categoryHim.pageId,
                                    bannerTitle: translate('valentine_page', 'him_title'),
                                    bannerUrl: categoryHim.url,
                                    id: categoryHim.id,
                                },
                            }}
                        />
                    }
                    {categoryHer
                        && <Promotion
                            editable={false}
                            component={{
                                ...promotionStatic,
                                ...{
                                    bannerAlt: categoryHer.name,
                                    bannerButtonText: translate('valentine_page', 'her_button_text'),
                                    bannerImageUri: translate('valentine_page', 'her_image_uri'),
                                    bannerPageId: categoryHer.pageId,
                                    bannerTitle: translate('valentine_page', 'her_title'),
                                    bannerUrl: categoryHer.url,
                                    id: categoryHer.id,
                                },
                            }}
                        />
                    }
                </div>
            </div>
        </Section>
        <Section isMd={!isMobile} noPadding={isMobile}>
            <div className={ClassNames(
                styles.AbTestValentinePage__heading,
                styles.AbTestValentinePage__heading_center,
            )}>
                <Heading tag={isMobile ? 'h3' : 'h2'} asText>
                    <Translation pageString="valentine_page" stringId="sub_category_title" />
                </Heading>
            </div>
        </Section>
        <Section noPadding hasBorderBottom>
            <AbTestSubCategories/>
        </Section>
        <Section noPadding>
            <div className={ClassNames(
                styles.AbTestValentinePage__heading,
                { 'mt-12 mb-2': isMobile },
                { 'mt-18': !isMobile },
                { [styles.AbTestValentinePage__heading_left as string]: !isMobile },
                { [styles.AbTestValentinePage__heading_center as string]: isMobile },
            )}>
                <Heading tag={isMobile ? 'h3' : 'h2'} asText>
                    <Translation pageString="valentine_page" stringId="product_title" />
                </Heading>
            </div>
        </Section>
    </>;
};

export default AbTestValentinePage;
