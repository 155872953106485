import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router';
import { Tracker } from '@yoursurprise/segment-analytics';
import type { UiState } from 'instantsearch.js';
import App from './Components/App';
import { document, window } from '../../../js/globals';
import { createFacetedSearchClient } from '../general/searchClient';
import slugify from '../../../js/util/slugify';
import updateSortBy from '../general/url/updateSortBy';
import { urlToUiState } from '../general/url/urlToUiState';

if (document && window && window.PRODUCT_CATEGORY_ALGOLIA_DATA) {
    const ssrElement = document?.getElementById('ssr-product-category-algolia');
    const pageId = document.getElementById('pageIdentifier')?.innerHTML;
    const pageTypeSlug = slugify(window.pageType ?? 'Other');

    const searchClient = createFacetedSearchClient(
        window?.PRODUCT_CATEGORY_ALGOLIA_DATA?.appID ?? '',
        window?.PRODUCT_CATEGORY_ALGOLIA_DATA?.searchApiKey ?? '',
    );

    const tracker = new Tracker();
    if (ssrElement && window.ALGOLIA_DATA) {
        const uiState : UiState = {
            [window.ALGOLIA_DATA.productIndexName]: updateSortBy(
                urlToUiState(window?.location.search ?? ''),
                window.ALGOLIA_DATA.productIndexName,
                window.ALGOLIA_DATA.replicaIndexBase,
            ),
        };

        const algoliaServerData = window.PRODUCT_CATEGORY_ALGOLIA_DATA.usesAlgolia ? window.ALGOLIA_SERVER_DATA : { initialResults: {} };

        hydrateRoot(
            ssrElement,
            <BrowserRouter>
                <App
                    initialUiState={uiState}
                    pageTypeSlug={pageTypeSlug}
                    contextData={window.siteMetadata}
                    algoliaServerState={algoliaServerData}
                    currency={window.PRODUCT_CATEGORY_ALGOLIA_DATA.currency}
                    components={window.PRODUCT_CATEGORY_ALGOLIA_DATA.components}
                    locale={window.PRODUCT_CATEGORY_ALGOLIA_DATA.locale}
                    pageId={pageId ? Number(pageId) : 0}
                    photoGiftUrl={window.PRODUCT_CATEGORY_ALGOLIA_DATA.photoGiftUrl}
                    productIndexName={window.PRODUCT_CATEGORY_ALGOLIA_DATA.productIndexName}
                    replicaIndexBase={window.PRODUCT_CATEGORY_ALGOLIA_DATA.replicaIndexBase}
                    searchClient={searchClient}
                    translations={window.PRODUCT_CATEGORY_ALGOLIA_DATA.translations}
                    tracker={tracker}
                />
            </BrowserRouter>,
        );

        delete window.ALGOLIA_SERVER_DATA;
        delete window.PRODUCT_CATEGORY_ALGOLIA_DATA;
    } else {
        const root = createRoot(document.getElementById('product-category-algolia') as Element);
        root.render(
            <BrowserRouter>
                <App
                    pageTypeSlug={pageTypeSlug}
                    currency={window.PRODUCT_CATEGORY_ALGOLIA_DATA.currency}
                    contextData={window.siteMetadata}
                    components={window.PRODUCT_CATEGORY_ALGOLIA_DATA.components}
                    locale={window.PRODUCT_CATEGORY_ALGOLIA_DATA.locale}
                    pageId={pageId ? Number(pageId) : 0}
                    photoGiftUrl={window.PRODUCT_CATEGORY_ALGOLIA_DATA.photoGiftUrl}
                    productIndexName={window.PRODUCT_CATEGORY_ALGOLIA_DATA.productIndexName}
                    replicaIndexBase={window.PRODUCT_CATEGORY_ALGOLIA_DATA.replicaIndexBase}
                    searchClient={searchClient}
                    translations={window.PRODUCT_CATEGORY_ALGOLIA_DATA.translations}
                    tracker={tracker}
                />
            </BrowserRouter>,
        );
    }
}
